import React, { Component } from 'react';
import { withAuthorization} from '../Session';
import * as FaIcons from 'react-icons/fa';
import * as GoIcons from 'react-icons/go';

class TasksPage extends Component {
  constructor(props) {
    super();   
    this.state = {
      loading: false,
      tasks: [],
    };
    this.doTask = this.doTask.bind(this);
  }

  doTask(props) {
    console.log(props)
    console.log(this)
 

    //Get taskStats for the task to be able to add the points
    if(taskHasStats(props)){
      //Increment value
      this.props.firebase.DofamilyMemberTasks(this.props.firebase.auth.currentUser.uid,this.props.match.params.id,props.uid)
      .set({
        lastTaskPerformedDateTime: Date.now()
        ,accumulatedPoints: +props.taskStats.accumulatedPoints + +props.taskPoints
      });
    }
    else {
      //Insert new record
      this.props.firebase.DofamilyMemberTasks(this.props.firebase.auth.currentUser.uid,this.props.match.params.id,props.uid)
      .set({
        lastTaskPerformedDateTime: Date.now()
        ,accumulatedPoints: props.taskPoints
      });
    }

  };
  
  

  componentDidUpdate(props) {
    let path = this.props.location.pathname;
    if (props.location.pathname !== path){
      this.componentDidMount();
    }
    
  }

  componentDidMount() {
    this.setState({ loading: true});

    this.props.firebase.familyMemberTasks(this.props.firebase.auth.currentUser.uid, this.props.match.params.id).on('value', snapshot => {
    const tasksObject = snapshot.val();

    let tasksObjectList = [];
    if(snapshot.val() != null){
      tasksObjectList = Object.keys(tasksObject).map(key => ({
        ...tasksObject[key],
        uid: key
      }))
    }else {
      tasksObjectList = []
    };
     

console.log(tasksObjectList)

    this.setState({
        tasks: tasksObjectList,
        loading: false,
      });
    });
  }

  
  componentWillUnmount(){
    this.props.firebase.familyMemberTasks(this.props.firebase.auth.currentUser.uid, this.props.match.params.id).off();
  }

  render(doTask){
    const {tasks, loading} = this.state;
    return (
      <div>
        <div className="topRightContainer">
            <GoIcons.GoGraph className="topRightIcon"/>
        </div>
        {loading && <FaIcons.FaReact className="loadingIcon"/>}
        <TasksList tasks={tasks} doTask={this.doTask.bind(this)}/>
      </div>
    )
  }
}

function diff_hours(dt2, dt1) 
 {
  var diff =(dt2.getTime() - dt1.getTime()) / 1000;
  diff /= (60 * 60);
  return Math.abs(Math.round(diff));
 }

function taskHasStats(task){
  if (task.taskStats){
    console.log('Task has stats - return true')
    return true;
  }
    else{
      console.log('Task does not have stats - return false.')
      return false;
    }
  }

function taskHasBeenDoneTodayCheck(task){
  console.log('taskHasBeenDoneTodayCheck' + 'checking ' + task.taskName )
  let today = new Date();
  let inputDate = new Date(0) 
  inputDate.setSeconds(task.taskStats.lastTaskPerformedDateTime/1000);
 
    if(today.setHours(0,0,0,0) === inputDate.setHours(0,0,0,0))
    { 
      console.log('Task ' + task.taskName + ' has been done today. HIDE.' )
      return true; }
    else 
    { 
      console.log('Task ' + task.taskName + ' has not been done today. SHOW.' )
      return false; 
    }    
   
}


function taskFrequencyCheck(task){
  console.log('taskFrequencyCheck checking ' + task.taskName )
  //Function should return true if task is missing frequency, or task frequency is lower or equal.
  //Should return false if frequency is higher.
  
  let today = new Date();
  let inputDate = new Date(0) 
  inputDate.setSeconds(task.taskStats.lastTaskPerformedDateTime/1000);
     
  if(task.frequencyHours){
    //Logic based on task has frequency.
    if(task.frequencyHours <= diff_hours(today,inputDate)){
      console.log('Frequency check: ' + task.taskName + ' should show.')
      return true
    }
    else {
      console.log('Frequency check: ' + task.taskName + ' should not show.')
      return false
    }
  }
  else{
    //Task has no frequency - thus should return true.
    return true;
  }
}

function taskDayCheck(task){

  var days = ['Sunday','Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  let toDay = new Date();
  console.log(toDay)
  var dayName = days[toDay.getDay()];

  if(task.taskDays){
    //If task has taskDays specified 
    
    //Check if its an array or single value
    if(task.taskDays.constructor === Array){
        if(task.taskDays.indexOf(dayName) > -1){
          return true;
        }
        else {
          //No days matching
          return false;
        }
    }
    else {
      if(task.taskDays === dayName){
        return true
      }
      else {
        //Day not matching
        return false;
      }
    }

  } else {
    //Task has no days specified - returing true
    return true
  }


  }

function shouldTaskBeShown(task){

  switch(task.taskType){
    case 'pickDays':
      if(taskDayCheck(task)){
        if(taskHasStats(task)){
          if(!taskHasBeenDoneTodayCheck(task)){
          return true;
        }
      }
      else {
        return true;
      }
    }
      break
    
    case 'daily':
      if(taskHasStats(task)){
      if(!taskHasBeenDoneTodayCheck(task)){return true;}
      } else {
        return true;
      }
      break
      
    case 'frequency':
      if(taskHasStats(task)){
        if(taskFrequencyCheck(task)){return true;}
      }
      else{
        return true;
      }     
      break

      case 'once':
        if(taskHasStats(task)){
          return false;
        }
        else{
          return true;
        }     
    
    default:
      //Unknown tasktype
      return false;
  }
}


const TasksList = ({ tasks, doTask }) => (
  
<div className="wrapper">
    {tasks.map((task) => {

      console.log(task)
      

      if(shouldTaskBeShown(task)){
          return (
          <div key={task.uid} id={task.uid} className="box" onClick={() => doTask(task)}>
            <p>{task.taskName}</p>
            <div className="box-taskPoints">{task.taskPoints}</div>
          </div>)
      }
        else{
          return(null)
        }
        
      }
    )}
  </div>
);


const condition = authUser => !!authUser;

export default withAuthorization(condition)(TasksPage);
