import React, {Component } from 'react';
import {withRouter} from 'react-router-dom';
import {compose} from 'recompose';

import * as ROUTES from '../../constants/routes';

import {v1 as uuid} from 'uuid';
import { withAuthorization } from '../Session';

const CreateFamilyMemberPage = () => (
  <div>
    <h1>Create Family Member</h1>
    <CreateFamilyMemberForm />
  </div>
);



const INITIAL_STATE = {
  firstname: '',
  familyMemberDOB: '',
  error: null
};


class CreateFamilyMemberFormBase extends Component {
  constructor(props){
    super(props);
    console.log(this)
    this.state = {
            ...INITIAL_STATE,
            loading: false,
        };


  }
  
  componentDidMount() {
      this.setState( {loading: true});
      console.log(this)
      
  }


  onChange = event => {
    this.setState({ [event.target.name]: event.target.value});
    console.log(this.state);

  };

  onSubmit = (event, props) => {
    const {firstname, familyMemberDOB} = this.state;
    console.log(firstname + ' ' + familyMemberDOB);
    console.log('event')
    console.log(event);
    console.log(props)
    this.props.firebase
          .familyMember(this.props.firebase.auth.currentUser.uid, uuid())
          .set({
            firstname,
            familyMemberDOB,
        })

        .then(() => {
          this.setState({ ...INITIAL_STATE });
          this.props.history.push(ROUTES.HOME);
        })
        .catch(error => {
          this.setState({error});
        });
      event.preventDefault();
    };

  
   render() {
    console.log(this)
    
    const {
      firstname,
      familyMemberDOB,
        error,
    } = this.state;

    const isInvalid =
    firstname === '' || familyMemberDOB === '';

    return (
      <form onSubmit={this.onSubmit}>
       <input
          name="firstname"
          value={firstname}
          onChange={this.onChange}
          type="text"
          placeholder="Name"
        />
        <input
          name="familyMemberDOB"
          value={familyMemberDOB}
          onChange={this.onChange}
          type="date"
          placeholder="Date of birth"
        />
        <button disabled={isInvalid} type="submit" props={this}>Sign Up</button>
        {error && <p>shit not working...</p>}
      </form>
    );
  }
}


const condition = authUser => !!authUser;

const CreateFamilyMemberForm = compose(
  withRouter,
  withAuthorization(condition),
)(CreateFamilyMemberFormBase);

export default CreateFamilyMemberPage;

export {CreateFamilyMemberForm};