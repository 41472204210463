import React, { Component } from 'react';
import { 
    HashRouter as Router
  , Route
  , Link
  } from 'react-router-dom';

import * as FaIcons from 'react-icons/fa';

import Navigation from '../Navigation';
import LandingPage from '../Landing';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import AccountPage from '../Account';
import AdminPAge from '../Admin';
import TasksPage from '../Tasks';
import CreateTaskPage from '../CreateTask';
import CreateFamilyMemberPage from '../CreateFamilyMember';


import * as ROUTES from '../../constants/routes';
import { withAuthentication, AuthUserContext } from '../Session';

class App extends Component {
  
  render(){
    return (
      <Router>
        
          <Navigation />
          <div className="page-content p-5">  
          <Route exact_path={ROUTES.LANDING} component={LandingPage} />
          <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
          <Route path={ROUTES.SIGN_IN} component={SignInPage} />
          <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
          <Route path={ROUTES.HOME} component={HomePage} />
          <Route path={ROUTES.ACCOUNT} component={AccountPage} />
          <Route path={ROUTES.ADMIN} component={AdminPAge} />
          <Route path={ROUTES.TASKS} component={TasksPage} />
          <Route path={ROUTES.CREATETASK} component={CreateTaskPage} />
          <Route path={ROUTES.CREATEFAMILYMEMBER} component={CreateFamilyMemberPage} />
          <AdminLink />
          </div>
          
        
      </Router>
      
      
    )
  }
}

/*



*/


const AdminLink = ({authUser}) => (
  <div>
    <AuthUserContext.Consumer>
      {authUser =>
      authUser 
              ? <div className="topLeftContainer">
                  <Link to={ROUTES.ADMIN}><FaIcons.FaCog className="topLeftIcon"/></Link>
                  </div> 
              : null
      }
    </AuthUserContext.Consumer>
          </div>
)

const condition = authUser => !!authUser;

export default withAuthentication(App);