import React, { Component } from 'react';
import * as ROUTES from '../../constants/routes';
import { Link} from 'react-router-dom';
import { withAuthorization } from '../Session';
import SignOutButton from '../SignOut';

class AdminPage extends Component {
  constructor(props) {
    super();
    };
  

  render(){
    

    return (
      <div>
        <h1>Admin</h1>
        <ul className="nav flex-column bg-white mb-0">
      <li className="nav-item">
        <Link to={ROUTES.ACCOUNT} className="nav-link text-dark font-italic bg-light">Account</Link>
      </li>
      <li className="nav-item">
        <Link to={ROUTES.CREATETASK} className="nav-link text-dark font-italic bg-light">Create Task</Link>
      </li>
      <li className="nav-item">
        <Link to={ROUTES.CREATEFAMILYMEMBER} className="nav-link text-dark font-italic bg-light">Manage family</Link>
      </li>
      <li className="nav-item">
        <SignOutButton />
      </li>
    </ul>
      </div>
    )
  }
}


const condition = authUser => !!authUser;

export default withAuthorization(condition)(AdminPage);