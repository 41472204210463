import React from 'react';
import {withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';


const withAuthorization = condition => Component => {
    class WithAuthorization extends React.Component {
        componentDidMount() {
            this.listener = this.props.firebase.auth.onAuthStateChanged(
                authUser => {
                    if(!condition(authUser)) {
                        this.props.history.push(ROUTES.SIGN_IN);
                        //console.log('Access denied authUser: ' + JSON.stringify(authUser));
                    }
                    //  else{
                    //      console.log('Access granted authUser: ' + JSON.stringify(authUser, null, 2));
                    //  }
                },
            );
        }
        componentWillUnmount(){
            this.listener();
        }

        render() {
            return (
            <AuthUserContext.Consumer>
                {authUser => 
                    condition(authUser) ? <Component {...this.props} /> : null}
            </AuthUserContext.Consumer>
            )
        }
    }
    return compose(
        withRouter,
        withFirebase,
    )(WithAuthorization);
};

export default withAuthorization;