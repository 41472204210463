import React, {Component } from 'react';
import {withRouter} from 'react-router-dom';
import {compose} from 'recompose';

import * as ROUTES from '../../constants/routes';

import {v1 as uuid} from 'uuid';
import { withAuthorization } from '../Session';

const CreateTaskPage = () => (
  <div>
    <h1>Create Task</h1>
    <CreateTaskForm />
  </div>
);



const INITIAL_STATE = {
  taskName: '',
  familyMemberId: '',
  taskPoints: '',
  frequency: '',
  taskType: '',
  taskDays: [] = '',
  error: null
};


class CreateTaskFormBase extends Component {
  constructor(props){
    super();
    
    this.state = {
            ...INITIAL_STATE,
            loading: false,
            familyMembers: [],
        };


  }
  
  componentDidMount() {
      this.setState( {loading: true});

      this.props.firebase.familyMembers(this.props.firebase.auth.currentUser.uid).on('value', snapshot => {
          const familyMembersObject = snapshot.val()

          const familyMembersList = Object.keys(familyMembersObject).map(key => ({
              ...familyMembersObject[key],
              uid: key
          }));

          this.setState({
              familyMembers: familyMembersList,
              loading: false,
          });
      });
  }

  componentWillUnmount(){
      this.props.firebase.familyMembers(this.props.firebase.auth.currentUser.uid).off();
  }

  onSubmit = event => {

    
    const {taskName, taskPoints, familyMemberId, taskDays, frequency, taskType} = this.state;
    

    //Calculate frequency hours
    let frequencyHours = 0;
    const regexFrequencyHour = /^\d+[hH]$/;
    const regexFrequencyDays = /^\d+[dD]$/;
    const regexFrequencyWeek = /^\d+[wW]$/;

    if(regexFrequencyHour.test(frequency)){frequencyHours = frequency.replace('h','')}
    if(regexFrequencyDays.test(frequency)){frequencyHours = (frequency.replace('d','')*24)}
    if(regexFrequencyWeek.test(frequency)){frequencyHours = ((frequency.replace('w','')*7)*24)}

    console.log(frequencyHours)
    //Done calculating frequency hours

    
    
    console.log(taskName + taskPoints + familyMemberId);
    this.props.firebase
          .familyMemberTask(this.props.firebase.auth.currentUser.uid, familyMemberId, uuid())
          .set({
            taskName,
            taskPoints,
            taskDays,
            frequencyHours,
            taskType,
        })

        .then(() => {
          this.setState({ ...INITIAL_STATE });
          this.props.history.push(ROUTES.HOME);
        })
        .catch(error => {
          this.setState({error});
        });
      event.preventDefault();
    };

onChange = event => {

    //Get and set state for all form object
    this.setState({ [event.target.name]: event.target.value});

    //Get and set state from options if target is taskDays
    if(event.target.name === 'taskDays'){
      if(event.target.options){
        var options = event.target.options;
            var value = [];
                    for (var i = 0, l = options.length; i < l; i++) {
                      if (options[i].selected) {
                        value.push(options[i].value);
                      }
                    }
      this.setState({taskDays: value});
      }
    }
};

render() {
    console.log(this)
    
    const {
        taskName,
        taskPoints,
        familyMemberId,
        familyMembers,
        frequency,
    } = this.state;

    
    const regexFrequency = /^\d+[hHdDwW]$/;
   

    const frequencyIsInvalid =
      regexFrequency.test(frequency) === false
      && frequency != '';

    const isInvalid =
      taskName === ''
      || taskPoints === ''
      || familyMemberId === ''
      || frequencyIsInvalid;


     return (


      
      <form onSubmit={this.onSubmit}>
        <select name="familyMemberId" id="familyMemberId" onChange={this.onChange}>
          <option disabled="disabled" selected={true} value=''>Choose familymember</option>
          <FamilyMembersList familyMembers={familyMembers}/>
        </select>

        <input name="taskName" value={taskName} onChange={this.onChange} type="text" placeholder="Type a short meaningfull name" />

        <input name="taskPoints" value={taskPoints} onChange={this.onChange} type="number" placeholder="How many points does this task give?"/>

        <select name="taskType" multiple={false} onChange={this.onChange}>
            <option disabled="disabled" selected={true}>Task repeat mode</option>
            <option key='daily' value="daily">Daily</option>
            <option key='pickDays' value="pickDays">Pick Days</option>
            <option key='frequency' value="frequency">Custom Frequency</option>
            <option key='Once' value="once">Occur once</option>
        </select>

        {this.state.taskType === 'pickDays' 
          ? <select name="taskDays" multiple={true} onChange={this.onChange}>
              <option key='Monday' value='Monday'>Monday</option>
              <option key='Tuesday' value='Tuesday'>Tuesday</option>
              <option key='Wednesday' value='Wednesday'>Wednesday</option>
              <option key='Thursday' value='Thursday'>Thursday</option>
              <option key='Friday' value='Friday'>Friday</option>
              <option key='Saturday' value='Saturday'>Saturday</option>
              <option key='Sunday' value='Sunday'>Sunday</option>
            </select> 
          : null
        }
        
        {this.state.taskType === 'frequency' 
          ? <input name="frequency" value={frequency} onChange={this.onChange} type="text" placeholder="Task frequency (format example 24h, 1d, 2w)"/> 
          : null
        }
        
        
        
        <button disabled={isInvalid} type="submit">Sign Up</button>
          {isInvalid && <p>You need to fill out all fields properly</p>}
          {frequencyIsInvalid && <p color="blue">Use frequency format 1d, 2w, 24h etc.</p>}
      </form>
    );
  }
}

const FamilyMembersList = ({ familyMembers}) => (
    
    familyMembers.map(familyMember => (
        <option key={familyMember.uid} value={familyMember.uid}>{familyMember.firstname}</option>
    ))

);

const condition = authUser => !!authUser;

const CreateTaskForm = compose(
  withRouter,
  withAuthorization(condition),
)(CreateTaskFormBase);

export default CreateTaskPage;

export {CreateTaskForm};