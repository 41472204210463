import React from 'react';

import {withAuthorization} from '../Session';

const HomePage = () => (
  <div className="body">
  <div className="page-content p-5" id="content">
  <h2 className="display-4">CtrlMyFamily</h2>            
  <div className="separator"></div>
  <div className="row"/>
  <div className="col-lg-7">
  </div>
</div>
</div>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(HomePage);