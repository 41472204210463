import React, { Component } from 'react';
import { Link} from 'react-router-dom';

import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext, withAuthorization } from '../Session';



class NavigationPage extends Component {
  constructor(props) {
    super();
    this.state = {
      loading: false,
      familyMembers: []
    };
  }

componentDidMount(){
  this.setState({loading : true});
  
  this.props.firebase.familyMembers(this.props.firebase.auth.currentUser.uid).on('value', snapshot => {
    const familyMembersObject = snapshot.val();
    
    let familyMembersList = [];
    if(snapshot.val() != null){familyMembersList = Object.keys(familyMembersObject).map(key => ({
      ...familyMembersObject[key],
      familyMemberId: key
    }))}
    else{familyMembersList = []}
    ;
    
    
    this.setState({
      familyMembers: familyMembersList,
      loading: false,
    });
  });
}

componentWillUnmount(){
  this.props.firebase.familyMembers().off();
}

render(){
  const {familyMembers, loading} = this.state;

  return (
    
    <div>
      {loading && <div>Loading </div>}
      <div className="vertical-nav bg-white" id="sidebar">
      <div className="py-4 bg-light">

              <div className="media-body">
                <Link to={ROUTES.HOME}>
                <div className="media d-flex align-items-center"><img src="https://icon-library.com/images/icon-for-family/icon-for-family-14.jpg" alt="..." width="65" className="mr-3 rounded-circle img-thumbnail shadow-sm"/>
                </div>
                </Link>

            </div>
          </div>
      {hasFamilyMembers(familyMembers) ? <FamilyMembersList familyMembers={familyMembers} /> : null}
      <NavigationSideNav />
      </div>
    </div>
    )
  }
}

function hasFamilyMembers(familyMemberList){
  if(familyMemberList.length !== 0){
    return true;
  }
  else {
    return false;
  }
}

const FamilyMembersList = ({familyMembers}) => (
  <div>
  {hasFamilyMembers(familyMembers)}

  {/* <p className="text-gray font-weight-bold text-uppercase px-3 small pb-4 mb-0">Family members</p> */}
  {familyMembers.map(familyMember => (

    <ul key={familyMember.familyMemberId} className="nav flex-column bg-white mb-0">
            <li className="nav-item">
            <Link to={"/tasks/" + familyMember.familyMemberId} className='nav-link text-dark font-italic bg-light'>
                      {familyMember.firstname}
                  </Link>
            </li>
            
          </ul>
  ))}
  </div>
);


//Side bar navigation
const NavigationSideNav = (authUser) => (
<div>
 
    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? <Navigation /> : <Navigation />}
    </AuthUserContext.Consumer>
        </div>
);

const Navigation = ({authUser}) => (
  <div>
    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? <NavigationAuth /> : <NavigationNonAuth />}
    </AuthUserContext.Consumer>
  </div>
  );

const NavigationAuth = () => (
  <div>
    <ul className="nav flex-column bg-white mb-0">
    </ul>
  </div>
  
  
);

const NavigationNonAuth = () => (
  <div>
    <ul>
      <li>
        <Link to={ROUTES.SIGN_IN}>Sign In</Link>
      </li>
      <li>
        <Link to={ROUTES.LANDING}>Landing</Link>
      </li>
    </ul>
  </div>
);




const condition = authUser => !!authUser;
export default withAuthorization(condition)(NavigationPage);