import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import * as fbConfig from './config';


  class Firebase {
      constructor(){
          app.initializeApp(fbConfig.config);

          this.auth = app.auth();
          this.db = app.database();
      }


      // *** AUTH API ***
      doCreateUserWithEmailAndPassword = (email, password) =>
      this.auth.createUserWithEmailAndPassword(email, password);

      doSignInWithEmailAndPassword = (email, password) =>
      this.auth.signInWithEmailAndPassword(email, password);

      doSignOut = () => this.auth.signOut();

      doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

      doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);


    // *** Firebase realtime database API ***

    user = uid => this.db.ref(`users/${uid}`);
    family = (familyId, uid) => this.db.ref(`users/${uid}/${familyId}`);
    familyMemberTask = (uid, familyMemberId, taskId) => this.db.ref(`users/${uid}/family/familyMembers/${familyMemberId}/tasks/${taskId}`);
    familyMemberTasks = (uid, familyMemberId) => this.db.ref(`users/${uid}/family/familyMembers/${familyMemberId}/tasks`);
    DofamilyMemberTasks = (uid, familyMemberId, taskId) => this.db.ref(`users/${uid}/family/familyMembers/${familyMemberId}/tasks/${taskId}/taskStats`);
    familyMember = (uid, familyMemberId) => this.db.ref(`/users/${uid}/family/familyMembers/${familyMemberId}`);
    familyMembers = (uid) => this.db.ref(`/users/${uid}/family/familyMembers`);
    users = () => this.db.ref('users');
  }

  export default Firebase;